(function insertLangDropdownValues() {
  const languages = {
    uk: 'ukr',
    en: 'eng',
    ru: 'rus',
  };

  const currentLang = document.documentElement.lang;
  const fullUrlPath = window.location.pathname;
  const logoButton = document.getElementById('header-logo');

  const splittedPath = fullUrlPath.split('/');
  const currentUrlPath = splittedPath[splittedPath.length - 1];

  const choosedLang = document.getElementsByClassName('header__lang-btn'); //choosed lang
  const langList = document.getElementsByClassName('header__lang-opt'); //3 elements

  const isLocal = window.location.host === 'localhost:1234';

  if (currentLang === 'uk') {
    logoButton.setAttribute('href', './');

    const cleanedUrlPath = currentUrlPath;
    choosedLang[0].innerText = languages.uk;

    langList[0].innerHTML = languages.uk;
    langList[1].innerHTML = languages.en;
    langList[2].innerHTML = languages.ru;

    langList[0].setAttribute('href', '');
    if (cleanedUrlPath.length > 1) {
      langList[1].setAttribute('href', './en-' + cleanedUrlPath);
      langList[2].setAttribute('href', './ru-' + cleanedUrlPath);
    } else {
      langList[1].setAttribute('href', './en' + (isLocal ? '.html' : ''));
      langList[2].setAttribute('href', './ru' + (isLocal ? '.html' : ''));
    }
  } else if (currentLang === 'en') {
    logoButton.setAttribute('href', './en' + (isLocal ? '.html' : ''));

    const cleanedUrlPath = currentUrlPath.replace('en-', '').replace('en', '');

    choosedLang[0].innerText = languages.en;

    langList[0].innerHTML = languages.en;
    langList[1].innerHTML = languages.uk;
    langList[2].innerHTML = languages.ru;

    langList[0].setAttribute('href', '');
    if (cleanedUrlPath.replace('.html', '').length >= 3) {
      langList[0].setAttribute('href', '');
      langList[1].setAttribute('href', './' + cleanedUrlPath);
      langList[2].setAttribute('href', 'ru-' + cleanedUrlPath);
    } else {
      langList[1].setAttribute('href', './');
      langList[2].setAttribute('href', './ru' + (isLocal ? '.html' : ''));
    }
  } else {
    logoButton.setAttribute('href', './ru' + (isLocal ? '.html' : ''));
    const cleanedUrlPath = currentUrlPath.replace('ru-', '');

    choosedLang[0].innerText = languages.ru;

    langList[0].innerHTML = languages.ru;
    langList[1].innerHTML = languages.uk;
    langList[2].innerHTML = languages.en;

    langList[0].setAttribute('href', '');
    if (cleanedUrlPath.replace('.html', '').length >= 3) {
      langList[0].setAttribute('href', '');
      langList[1].setAttribute('href', './' + cleanedUrlPath);
      langList[2].setAttribute('href', './en-' + cleanedUrlPath);
    } else {
      langList[1].setAttribute('href', './');
      langList[2].setAttribute('href', './en' + (isLocal ? '.html' : ''));
    }
  }
})();

function openDropdown() {
  document.getElementById('myDropdown').classList.toggle('show');
}
function closeDropdown() {
  const dropdown = document.getElementById('myDropdown');
  if (dropdown.classList.contains('show')) {
    dropdown.classList.remove('show');
  }
}

(function handleDropdown() {
  const buttonLang = document.getElementsByClassName('header__lang-btn')[0]; //choosed lang
  const dropdown = document.getElementById('myDropdown');

  if (window.screen.width < 760) {
    buttonLang.addEventListener('touchstart', openDropdown);
  } else {
    buttonLang.addEventListener('mouseover', openDropdown);
    dropdown.addEventListener('mouseleave', closeDropdown);
  }
})();

// Close the dropdown menu if the user clicks outside of it
window.onclick = function (event) {
  if (!event.target.matches('.header__lang-btn')) {
    closeDropdown();
  }
};

// (function preventDefault(e) {
//   e.preventDefault();
//   document.body.addEventListener('touchmove', preventDefault, {
//     passive: false,
//   });
// })();

(function showArrowOnScroll() {
  const arrowContainer = document.querySelector('.arrow-container');
  const windowHeight = window.innerHeight;
  let isElementVisible = false;

  window.addEventListener('scroll', () => {
    const scrollPosition = window.scrollY;

    if (!isElementVisible && scrollPosition > windowHeight) {
      arrowContainer.style.visibility = 'visible';
      isElementVisible = true;
    } else if (isElementVisible && scrollPosition <= windowHeight) {
      arrowContainer.style.visibility = 'hidden';
      isElementVisible = false;
    }
  });
})();
